/**
 * @typedef {Object} RingpoolSettings
 * @property {String} pageType - the `pagetype` value on the page (or empty string if missing)
 * @property {String} experimentID - the currently running Google Optimize experiment id (or `null` if no experiment is running)
 * @property {String} variationID - the currently running Google Optimize variation id (or `null` if no experiment is running)
 * @property {"mobile"|"desktop"} device - the current device type (`mobile` or `desktop`)
 * @property {"1"} swimlaneID - always hard-coded to `1`
 */
/**
 * @typedef RingpoolResponse
 * @property {String} rpid - the ringpool id
 * @property {String} tfn - the dynamic phone number returned by Ring Center
 */

import getGoogleIDs, { debugGoEvent } from '../tracking/getGoogleIDs';
import {getQSValue, isSemUser} from '../tracking';
import {getS1Value} from '../tracking/getSValues';
import isMobileScreen from '../isMobileScreen';

const RINGPOOL_API_BASE = 'https://rs.cfringctr.com/api/ReserveNumbersV2';
// const CUSTOM_NATIVE_6_COOKIE_NAME = 'CF_CUSTOM_NATIVE_6';

// this is our cache for ringpool responses, so we don't have to re-query the network API for the same ringpool ids and settings
// this cache is keyed with a string representation of the ringpool settings
//
const inMemoryCache = new Map();
const childPageRingpoolCache = new Map();
let childPageTrackingRunning = false;

/**
 * Fetch unique URL parameter from the current page.
 */
export function getUniqueUrlParam(param)
{
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
}

/**
 * Convert an array of ringpools to an object which maps ringpool ids to phone numbers.
 * @param ringpools - array of ringpool objects as returned by `getNumberForRingpools`
 * @see {link getNumberForRingpools}
 * @returns {Object} - keys are ringpool ids, values are phone numbers
 */
export function ringpoolsToMap(ringpools)
{
    let mappedRingpools = {};
    ringpools.forEach(ringpool => {
        const rpid = ringpool.rpid;
        const tfn = ringpool.tfn;
        if (typeof(mappedRingpools[rpid.toString()]) === 'undefined') {
            mappedRingpools[rpid.toString()] = tfn;
        }
    });
    return mappedRingpools;
}

/**
 * Decode an obfuscated number from the Ring Center API into basic ASCII.
 * @param encodedNumber
 * @returns {String} - the phone number in plaintext
 */
export function decodeNumber(encodedNumber)
{
    return encodedNumber.replace(/&#(\d+);/g, function (match, dec) {
        return String.fromCharCode(dec);
    }).replace(/\u200b/g, '');
}

function populateRingpoolSettings(customField, experimentId, variationId, typeid = 1)
{
    let passedExperimentId = experimentId === '' ? 'no experiment' : experimentId;
    let passedVariationId = variationId === '' ? 'no variant' : variationId;

    let ringpoolSettings = {
        'pageType': (customField ? customField.value : 'pagetype-unknown'),
        'experimentID': passedExperimentId,
        'variationID': passedVariationId,
        'device': (isMobileScreen() ? 'mobile' : 'desktop'),
        'swimlaneID': '1', // purposefully hard-coded, swimlane is passed to ringpool but not used by native reservation
        'hasUniquePhoneReservation': '1', // Ringpool Top 100 tracked cities / zips
        'typeid': typeid,
    };

    return ringpoolSettings;
}

/**
 * Retrieve parameters passed to native ringpool service
 * @returns {Promise<RingpoolSettings>}
 */
export function getRingpoolSettings(typeid = 1)
{
    debugGoEvent({
        'event': 'goExperimentRingpoolFetchStart',
    });

    return new Promise((resolve) => {
        const customField = document.getElementById('pagetype');

        getGoogleIDs('getRingpoolSettings')
            .then(googleIDs => {
                if (googleIDs.optimizeData === null) {
                    let ringpoolSettings = populateRingpoolSettings(customField, null, null, typeid);

                    return resolve(ringpoolSettings);
                }

                let experimentId = window.site_settings.experiment.id ?? '';
                let variationId = window.site_settings.experiment.id ?? '';

                let ringpoolSettings = populateRingpoolSettings(
                    customField,
                    experimentId === '' ? 'no experiment' : experimentId,
                    variationId === '' ? 'no variant' : variationId,
                    typeid
                );
                debugGoEvent({
                    'event': 'goExperimentRingpoolSettingsCompleted', ringpoolSettings,
                });

                return resolve(ringpoolSettings);
            })
            .catch(error => {
                let ringpoolSettings = populateRingpoolSettings(customField, null, null, typeid);
                debugGoEvent({
                    'event': 'goExperimentRingpoolFetchError', error,
                });

                return resolve(ringpoolSettings);
            });
    });
}

/**
 * Fetch dynamic ringpool data from the Ring Center API, and cache the results in memory.
 * If a particular ringpool id has been cached, the Ring Center API will not be requested again for that ringpool id unless the ringpool settings change.
 * In effect, this means you can call this function multiple times and network requests will only go out when there is new data.
 * @see {link getSettingsForRingpool}
 * @param ringpools {[number]|number} - which ringpool ids to query for dynamic numbers
 * @returns {Promise<[RingpoolResponse]>}
 */
export function getNumberForRingpools(ringpools, typeid = 1)
{
    // convert input data from a variety of potential formats, but really we expect an array of integers
    if (typeof(ringpools) === 'number') {
        ringpools = [ringpools];
    } else if (typeof(ringpools) === 'string') {
        let ringpoolInt = parseInt(ringpools, 10);
        if (!isNaN(ringpoolInt)) {
            return getNumberForRingpools(ringpoolInt);
        } else {
            console.error(`Invalid ringpool id: ${ringpools}`);
        }
    } else if (!Array.isArray(ringpools)) {
        console.error(`Invalid ringpool id: ${ringpools}`);
    }

    return new Promise((resolve) => {
        return getRingpoolSettings(typeid)
            .then((ringpoolSettings) => {
                // create a stable string key for the current ringpool settings
                // this is required since Maps cannot use a plain Javascript object as a key
                const ringpoolSettingsKey = JSON.stringify(ringpoolSettings, Object.keys(ringpoolSettings).sort());
                if (!inMemoryCache.has(ringpoolSettingsKey)) {
                    // create an empty object for storing ringpool data, if this is a new set of ringpool settings
                    inMemoryCache.set(ringpoolSettingsKey, {});
                }

                // we divide our desired ringpools into cached and uncached
                // we only need to trigger a network request for uncached ringpools
                const cachedRingpoolMap = inMemoryCache.get(ringpoolSettingsKey);
                let cachedRingpoolIds = [];
                let uncachedRingpoolIds = [];
                ringpools.forEach(rpid => {
                    if (Object.prototype.hasOwnProperty.call(cachedRingpoolMap, rpid)) {
                        cachedRingpoolIds.push(rpid);
                    } else {
                        uncachedRingpoolIds.push(rpid);
                    }
                });

                // cached ringpool promise directly returns the ringpool data from the cache
                let cachedRingpoolPromise = new Promise((resolve) => {
                    return resolve(cachedRingpoolIds.map(rpid => {
                        return {
                            tfn: cachedRingpoolMap[rpid],
                            rpid: rpid,
                        }
                    }));
                });

                // uncached ringpool ids require a network request to Ring Center
                let networkRingpoolPromise = new Promise((resolve) => {
                    if (uncachedRingpoolIds.length === 0) {
                        // if we have none, return empty array rather than firing a network request with no ringpools
                        return resolve([]);
                    } else {
                        // TODO: forces ringpool to work by replicating the zip page passed parameters. delete after release and/or testing
                        // ringpoolSettings.pageType = 'pagetype-zip';
                        // ringpoolSettings.variationID = '0';
                        // ringpoolSettings.experimentID = 'zip';
                        // format the ringpools in the proper format for the API
                        // TODO: REMOVED FOR RELEASE. REINTRODUCE AFTER RELEASE
                        // const attr5Value = (ringpoolSettings.hasUniquePhoneReservation) ? ringpoolSettings.hasUniquePhoneReservation : ringpoolSettings.swimlaneID;

                        const formattedRingpools = `[${uncachedRingpoolIds.map(rp => `"${rp}"`).join(',')}]`;
                        const queryParams = new URLSearchParams({
                            ringPoolIDs: formattedRingpools,
                            typeid: ringpoolSettings.typeid,
                            subid: getS1Value(),
                            aid: getQSValue('a'),
                            attr1: ringpoolSettings.pageType,
                            attr2: ringpoolSettings.experimentID,
                            attr3: ringpoolSettings.variationID,
                            attr4: ringpoolSettings.device,
                            attr5: ringpoolSettings.swimlaneID,
                            //attr6: getCookie(CUSTOM_NATIVE_6_COOKIE_NAME),
                        });

                        const apiUrl = `${RINGPOOL_API_BASE}?${queryParams}`;
                        return fetch(apiUrl)
                            .then(response => response.json())
                            .then(data => {
                                return resolve(data.response.map(reservation => {
                                    // ringpool data is returned from Ring Center with encoded numbers and various other properties we don't care about
                                    // so we convert this into a format we can properly use (and which matches the cached number format)
                                    const reservationResult = {
                                        tfn: decodeNumber(reservation.number),
                                        rpid: reservation.ring_pool_id,
                                    };
                                    // Debugging:
                                    //console.log('reservation typeid: ', typeid);
                                    console.log('reservation response', reservationResult);

                                    return reservationResult
                                }));
                            });
                    }
                });

                // wait until the networked and cached ringpools have been processed before moving onto the next step
                // uncached ringpools should be near instantaneous but the network request will take some unknown time
                // we also pass the ringpool settings key which is needed for caching the newly-fetched ringpools
                return Promise.all([networkRingpoolPromise, cachedRingpoolPromise, new Promise(resolve => resolve(ringpoolSettingsKey))]);
            })
            .then(([networkedRingpools, cachedRingpools, ringpoolSettingsKey]) => {
                let currentlyCachedRingpools = inMemoryCache.get(ringpoolSettingsKey);
                let allReturnedRingpools = cachedRingpools.concat(networkedRingpools);

                let ringpoolObject = {};
                allReturnedRingpools.forEach(ringpool => {
                    ringpoolObject[ringpool.rpid] = ringpool.tfn;
                });

                // combine current cache with newly-fetched ringpool data to form a complete cache, and store it
                let newRingpoolCache = Object.assign(currentlyCachedRingpools, ringpoolObject);
                inMemoryCache.set(ringpoolSettingsKey, newRingpoolCache);

                return resolve(allReturnedRingpools);
            });
    });
}

export function getNumberForChildPageTracking(ringpool, typeid = 2, timeout = 3000)
{
    if (childPageTrackingRunning) {
        // Debugging:
        console.log('Ringpool Reservation for child page is already running...');
        return Promise.resolve(null);
    }

    let passedRingpool = null;
    if (typeof(ringpool) === 'number') {
        passedRingpool = ringpool;
    } else if (typeof(ringpool) === 'string') {
        let passedRingpool = parseInt(ringpool, 10);
        if (!isNaN(passedRingpool) && passedRingpool > 0) {
            return getNumberForChildPageTracking(passedRingpool, typeid, timeout);
        } else {
            console.error(`Invalid ringpool id: ${ringpool}`);
        }
    }

    // Check if the ringpool data is already in the cache
    if (childPageRingpoolCache.has(passedRingpool)) {
        // Debugging:
        console.log('Loading Ringpool Reservation for child page from cache...');
        console.log('Loaded Ringpool Reservation for child page completed from cache:', childPageRingpoolCache.get(passedRingpool));

        return Promise.resolve(childPageRingpoolCache.get(passedRingpool));
    }

    let isResolved = false;
    let timeoutPromise = new Promise((resolve) => {
        setTimeout(() => {
            if (!isResolved) {
                // Debugging:
                console.log(`Ringpool reservation timed out after ${timeout/1000} seconds`);
                resolve(null); // Resolve with null after 5 seconds only if not resolved already
            }
        }, timeout);
    });

    let reservationPromise = new Promise((resolve, reject) => {
        childPageTrackingRunning = true;

        getRingpoolSettings(typeid)
            .then((ringpoolSettings) => {
                const formattedRingpool = `["${passedRingpool}"]`;
                const queryParams = new URLSearchParams({
                    ringPoolIDs: formattedRingpool,
                    typeid: ringpoolSettings.typeid,
                    subid: getS1Value(),
                    aid: getQSValue('a'),
                    attr1: ringpoolSettings.pageType,
                    attr2: ringpoolSettings.experimentID,
                    attr3: ringpoolSettings.variationID,
                    attr4: ringpoolSettings.device,
                    attr5: ringpoolSettings.swimlaneID,
                    //attr6: getCookie(CUSTOM_NATIVE_6_COOKIE_NAME),
                });

                const apiUrl = `${RINGPOOL_API_BASE}?${queryParams}`;
                return fetch(apiUrl)
                    .then(response => {
                        if (response.ok) {
                            return response.json();
                        } else {
                            throw new Error('Failed to fetch data');
                        }
                    })
                    .then(data => {
                        if (data === null) {
                            const errorMessage = `Error fetching ringpool data at: ${RINGPOOL_API_BASE}?${queryParams}`;
                            console.error(errorMessage);
                            throw new Error(errorMessage);
                        }

                        const reservationObject = data.response[0];
                        const reservation = {
                            tfn: decodeNumber(reservationObject.number),
                            rpid: reservationObject.ring_pool_id,
                            typeid: typeid,
                            ...ringpoolSettings,
                        };
                        // Debugging:
                        console.log('reservation: ', reservation);
                        // Cache the retrieved ringpool data
                        childPageRingpoolCache.set(passedRingpool, reservation);
                        isResolved = true;
                        resolve(reservation);
                    });
            })
            .catch(error => {
                console.error('Error fetching ringpool data:', error);
                reject(error); // Reject the promise when an error occurs
            });
    });

    Promise.race([reservationPromise, timeoutPromise])
        .then((reservation) => {
            childPageTrackingRunning = false;

            // Debugging:
            if (reservation !== null) {
                console.log('Ringpool Reservation for child page completed!');
            } else {
                console.log('Ringpool Reservation for child page timed out!');
            }

            return reservation;
        });
}

// TODO: Keep function for future use such as for attr6 within ringpool call
// export function getCookie(cname)
// {
//     const name = cname + '=';
//     const decodedCookie = decodeURIComponent(document.cookie);
//     const ca = decodedCookie.split(';');
//     for (let i = 0; i < ca.length; i++) {
//         let c = ca[i];
//         while (c.charAt(0) === ' ') {
//             c = c.substring(1);
//         }
//         if (c.indexOf(name) === 0) {
//             return c.substring(name.length, c.length);
//         }
//     }
//     return '';
// }

// our default function automatically replaces all ringpools in the DOM with dynamic numbers
// this should be called on page load for every page
// however, if you are only importing this file for API-driven ringpools, you do not need to run this default function
export function ringpoolSEM () {
    let ringpoolNumbers = '',
        rpids = [];
    const ringPoolLoaded = new CustomEvent('ringPoolLoaded', {});
    let typeid = 1;

    // only need to check first phone button if ringpool is loaded
    const phoneButton = document.querySelector('[data-ringpool-sem-phone]');
    if (phoneButton === null) {
        return;
    }
    if (isSemUser()) {
        typeid = 2
        // go through the DOM and search for all elements with data-ringpool-alt-id data attribute
        ringpoolNumbers = Array.from(document.querySelectorAll('[data-ringpool-alt-id]'));

        // deduplicate array of ringpool ids
        rpids = ringpoolNumbers.map(node => node.dataset.ringpoolAltId).filter((rpid, index, arr) => arr.indexOf(rpid) === index);
    } else {
        // we are an SEO user! let's automatically process all the ringpool numbers on the page at page load
        // the rest can query our API directly, if there are any dynamically inserted numbers (from AJAX and such)

        // go through the DOM and look for all nodes tagged with data-ringpool-id
        ringpoolNumbers = Array.from(document.querySelectorAll('[data-ringpool-id]'));

        // deduplicate array of ringpool ids
        rpids = ringpoolNumbers.map(node => node.dataset.ringpoolId).filter((rpid, index, arr) => arr.indexOf(rpid) === index);
    }

    // fetch the ringpool data for those ids (cached or uncached, we don't care)
    getNumberForRingpools(rpids, typeid)
        .then(ringpools => ringpoolsToMap(ringpools))
        .then(mappedRingpools => {
            // Debugging:
            console.log('mappedRingpools', mappedRingpools);
            ringpoolNumbers.forEach(node => {
                const rpid = node.dataset.ringpoolId ?? node.dataset.ringpoolAltId;
                const tfn = mappedRingpools[rpid];
                if (typeof(tfn) !== 'undefined') {
                    // finally, set the phone number in the DOM node, and we're done!
                    node.setAttribute('data-phone', tfn);
                }
            });
            // dispatch ringpool loaded event
            if (phoneButton !== null) {
                phoneButton.dispatchEvent(ringPoolLoaded);
            }
        });
}
