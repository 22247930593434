import GoogleDataFetcher from '../../utilities/googleDataFetcher';

export default function getGoogleIDs(sender) {
    console.log('getGoogleIDs called from:', sender);

    let googleIDs = GoogleDataFetcher.getGoogleData(sender);
    return googleIDs;
}

export const debugGoEvent = (data) =>
{
    window.dataLayer.push({
        ...data,
    });
}

