// menu open
const menuToggler = (e) => {
    const menuChild = e.currentTarget.parentNode.querySelector('.nav-items').classList;
    const subMenuChilds = e.currentTarget.parentNode.querySelectorAll('.nav-items .menu-item-has-children');

    e.preventDefault();

    e.currentTarget.classList.toggle('open');

    if(menuChild.contains('open') || menuChild.contains('closed') ) menuChild.remove('open', 'closed');

    subMenuChilds.forEach(elem => {
        if(elem.classList.contains('open')) elem.classList.remove('open');
    });
};

const menuButton = document.querySelector('#primary_navigation .menu-button')

menuButton.addEventListener('click', (e) => menuToggler(e));
menuButton.addEventListener('touchend', (e) => menuToggler(e));

// submenu open
const submenuToggler = (e) => {
    const menuParent =
        (e instanceof Event) ?
            e.currentTarget.closest('.nav-items').classList :
            e.closest('.nav-items').classList;

    e.currentTarget.parentNode.classList.toggle('open');

    if(menuParent.contains('open') || menuParent.contains('closed') ) {
        menuParent.toggle('open');
        menuParent.toggle('closed');
    } else {
        menuParent.toggle('open');
    }
};

document
    .querySelectorAll('#primary_navigation li.menu-item > span.parent')
    .forEach(elem =>
        elem.addEventListener('click', e => {
            e.preventDefault();

            (window.innerWidth < 992) && submenuToggler(e);
        })
    );


// Add class to menus with columns
const assignMenuPositions = ()=>  {
    
    const topLevels = document.querySelectorAll('#primary_navigation ul.main-menu li.top-level');

    for (let topLevel of topLevels) {
        const subMenu = topLevel.querySelector(':scope > ul.sub-menu');

        if (subMenu.querySelectorAll('li.menu-item-has-children').length === 0) {
            subMenu.classList.add('not-grandparent');
        } else {
            subMenu.classList.add('is-grandparent');
        }

        var menu = topLevel.querySelector('ul.is-grandparent');

        if (typeof menu !== 'undefined' && menu !== null) {
            //reset position
            menu.style.left = 0 + 'px';
            
            var menuRect = menu.getBoundingClientRect(),
                menuLeft = menuRect.left + window.scrollX,
                menuWidth = menu.getBoundingClientRect().width,
                menuTotal = parseInt(menuLeft) + parseInt(menuWidth),
                windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

            if (menuTotal > windowWidth) {
                var newpos = Math.round(menuTotal - windowWidth) + 20;
                menu.style.left = newpos * -1+'px';
            }
        }
    }
}

window.addEventListener('load', assignMenuPositions);

//handle scroll events with a throttled delay for performance and accurate position data
let scrollTimer = -1;

window.addEventListener('resize', function() {
    if (scrollTimer != -1) {
        clearTimeout(scrollTimer);
    }

    scrollTimer = window.setTimeout(assignMenuPositions, 300);
});