export default function init() {
    const medicareBanner = document.querySelector('.medicare-sticky-promo-container');
    if (medicareBanner) {
        const closeButton = medicareBanner.querySelector('.medicare-sticky-promo-close');
        if (closeButton) {
            closeButton.addEventListener('click', () => {
                medicareBanner.remove();
            });
        }
    }
}