// see https://github.com/Modernizr/Modernizr/blob/master/feature-detects/storage/localstorage.js
export function isLocalStorageAvailable() {
  try {
    localStorage.setItem('localStorageAvailable', 'localStorageAvailable');
    localStorage.removeItem('localStorageAvailable');
    return true;
  } catch (e) {
    return false;
  }
}
