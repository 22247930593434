const
    REFERRAL_CTA_SELECTOR = '[data-ga="referral-cta"]',
    REFERRAL_CALL_SELECTOR = '[data-ga="referral-call"]',
    MIDCONTENT_ADVISOR_CTA = document.querySelectorAll('section.advisor .housing-cta-btn'),
    MIDCONTENT_ASSESSMENT_CTA = document.querySelectorAll('section.assessment .housing-cta-btn'),
    SENIOR_FINDER_ZIP_FORM = document.querySelectorAll('.senior-finder-form'),
    //SENIOR_FINDER_ZIP_BTN = document.querySelectorAll('#hero-c2a-v2 .geodir_submit_search'),
    TAKEOVER_BANNER_IN_CONTENT_BTN = document.querySelectorAll('.takeover-content--cta-button a.btn'),
    TAKEOVER_BANNER_HEADER_BTN = document.querySelectorAll('#takeover-notification-banner a.btn'),
    TAKEOVER_BANNER_FOOTER_BTN = document.querySelectorAll('#takeover-notification-banner-footer a.btn'),
    //HEARING_TOOL_CTA = document.querySelectorAll('#hearing-tool a'),
    PAGETYPE_FIELD = document.getElementById('pagetype'),
    CUSTOM_FIELD = document.getElementById('customfield1'),
    INTERNAL_LINKS_SELECTOR = '[data-link-type="internal"]',
    EXTERNAL_LINKS_SELECTOR = '[data-link-type="external"]',
    ON_PAGE_LINKS_SELECTOR = '[data-link-type="on-page"]',
    ON_PAGE_LINKS_HOVER_CLICK_SELECTOR = '[data-link-type="on-page-click-hover"]',
    SEARCH_FORM = document.querySelector('.search-form'),
    PAGINATION = document.querySelectorAll('.pagination a.page-link');

function triggerEventTracking() {
    // Get context of page from customfield1
    const context = CUSTOM_FIELD ? CUSTOM_FIELD.value : '',
        pageTypeValue = PAGETYPE_FIELD ? PAGETYPE_FIELD.value : 'generic';

    // Get Product Category of CTA
    function productCategory($element)
    {
        return $element.dataset.productcategory || null;
    }

    // Get Page Type of CTA
    function pageType($element)
    {
        return $element.dataset.pagetype || pageTypeValue;
    }

    // Get Product Name of CTA
    function productName($element)
    {
        return $element.dataset.productname || null;
    }

    // Get Provider CID of CTA
    function providerCID($element) {
        return $element.dataset.providercid || '0'
    }

    // Get Sale Marking of CTA
    function saleCTA($element)
    {
        return $element.dataset.sale || 'false';
    }

    // Get Element Placement of CTA
    function elementPlacement($element)
    {
        return $element.dataset.elementplacement || null;
    }

    // Get CTA URL of CTA
    function ctaUrl($element)
    {
        return $element.href || null
    }

    // Get Index of CTA
    function providerIndex($element)
    {
        return $element.dataset.order || 'single'
    }

    // Get Destination Type of CTA
    function destinationType($element)
    {
        return $element.dataset.destination || 'generic'
    }

    // Get Element Clicked of CTA
    function elementClicked($element)
    {
        return $element.dataset.elementclicked || ''
    }

    function getDirection($element)
    {
        return $element.dataset.direction || '';
    }

    // Function to allow multiple event listeners on the same element but call separate functions for each event
    function addMultipleEventListeners(element, events, functions) {
        for (let i = 0; i < events.length; i++) {
            document.addEventListener(events[i], function (e) {
                const elementTarget = e.target.closest(element);
                if (elementTarget) {
                    functions[i](elementTarget);
                }
            });
        }
    }

    // Get Element Clicked of CTA
    function linkText($element)
    {
        if ($element.innerText !== '') {
            return $element.innerText;
        } else if ($element.innerHTML.includes('img') || $element.innerHTML.includes('svg')) {
            return 'image';
        } else if ($element.innerText === '') {
            return 'other';
        } else {
            return '';
        }
    }

    function buildDatalayerParams($element)
    {
        return {
            'href': ctaUrl($element),
            'serviceProvider': productName($element),
            'pageType': pageType($element),
            'productCategory': productCategory($element),
            'activePromotion': saleCTA($element),
            'elementPlacement': elementPlacement($element),
            'position': providerIndex($element),
            'linkText': linkText($element),
            'providerCID': providerCID($element),
        }
    }

    // Takeover Banner header cta
    if (TAKEOVER_BANNER_HEADER_BTN) {
        TAKEOVER_BANNER_HEADER_BTN.forEach(btn => {
            btn.addEventListener('click', (e) => {
                const elementTarget = e.currentTarget;
                const defaultData = {
                    'event': 'sponsored_click_out',
                    'elementClicked': 'takeover banner',
                    'destinationType': destinationType(elementTarget),
                    'elementPlacement': 'header',
                    'elementName': 'header Takeover banner',
                    'serviceProvider': null,
                    'pageType': context,
                    'href': ctaUrl(elementTarget),
                };
                window.dataLayer.push(defaultData);

                console.log(defaultData);
            });
        });
    }

    // Takeover Banner footer cta
    if (TAKEOVER_BANNER_FOOTER_BTN) {
        TAKEOVER_BANNER_FOOTER_BTN.forEach(btn => {
            btn.addEventListener('click', (e) => {
                const elementTarget = e.currentTarget;
                const defaultData = {
                    'event': 'sponsored_click_out',
                    'elementClicked': 'takeover banner',
                    'destinationType': destinationType(elementTarget),
                    'elementPlacement': 'footer',
                    'elementName': 'Footer Takeover banner',
                    'serviceProvider': null,
                    'pageType': context,
                    'href': ctaUrl(elementTarget),
                };
                window.dataLayer.push(defaultData);

                console.log(defaultData);
            });
        });
    }

    // Takeover Banner in content cta
    if (TAKEOVER_BANNER_IN_CONTENT_BTN) {
        TAKEOVER_BANNER_IN_CONTENT_BTN.forEach(btn => {
            btn.addEventListener('click', (e) => {
                const elementTarget = e.currentTarget;
                const defaultData = {
                    'event': 'sponsored_click_out',
                    'elementClicked': 'takeover banner',
                    'destinationType': destinationType(elementTarget),
                    'elementPlacement': 'in-content',
                    'elementName': 'In-content Takeover banner',
                    'serviceProvider': null,
                    'pageType': context,
                    'href': ctaUrl(elementTarget),
                };
                window.dataLayer.push(defaultData);

                console.log(defaultData);
            });
        });
    }

    // Senior Finder Mid-Content CTA Advisor
    if (MIDCONTENT_ADVISOR_CTA) {
        MIDCONTENT_ADVISOR_CTA.forEach(btn => {
            btn.addEventListener('click', (e) => {
                const elementTarget = e.currentTarget;
                // Old push
                window.dataLayer.push({
                    'event': 'housing_cta_adivsor',
                    'pageContext': context,
                });

                // New push for click out
                const defaultData = {
                    'event': 'cta_click_out',
                    'elementClicked': 'housing cta advisor',
                    'destinationType': 'internal',
                };
                const dataLayerData = Object.assign(defaultData, buildDatalayerParams(elementTarget));
                window.dataLayer.push(dataLayerData);

                console.log(dataLayerData);

                // New push for lead form entry
                window.dataLayer.push({
                    'event': 'cta_lead_form_click',
                    'elementPlacement': 'in-content',
                    'elementClicked': 'Mid-content Advisor CTA',
                    'pageType': pageTypeValue,
                    'href': ctaUrl(elementTarget),
                });

                console.log({
                    'event': 'cta_lead_form_click',
                    'elementPlacement': 'in-content',
                    'elementClicked': 'Mid-content Advisor CTA',
                    'pageType': pageTypeValue,
                    'href': ctaUrl(elementTarget),
                });
            });
        });
    }

    // Senior Finder Mid-Content CTA Assessment
    if (MIDCONTENT_ASSESSMENT_CTA) {
        MIDCONTENT_ASSESSMENT_CTA.forEach(btn => {
            btn.addEventListener('click', (e) => {
                const elementTarget = e.currentTarget;
                // Old push
                window.dataLayer.push({
                    'event': 'housing_cta_assessment',
                    'pageContext': context,
                });


                // New push for click out
                const defaultData = {
                    'event': 'cta_click_out',
                    'elementClicked': 'housing cta assessment',
                    'destinationType': 'internal',
                };
                const dataLayerData = Object.assign(defaultData, buildDatalayerParams(elementTarget));
                window.dataLayer.push(dataLayerData);

                console.log(dataLayerData);

                // New push for lead form entry
                window.dataLayer.push({
                    'event': 'cta_lead_form_click',
                    'elementPlacement': 'in-content',
                    'elementClicked': 'Mid-content Assessment CTA',
                    'pageType': pageTypeValue,
                    'href': ctaUrl(elementTarget),
                });
            });
        });
    }

    // Senior Finder Mid-Content CTA Assessment
    // if (SENIOR_FINDER_ZIP_BTN) {
    //     SENIOR_FINDER_ZIP_BTN.forEach(btn => {
    //         btn.addEventListener('click', (e) => {
    //             const elementTarget = e.currentTarget;
    //             // Old push
    //             window.dataLayer.push({
    //                 'event': 'housing_cta_zipsearch',
    //                 'pageContext': context,
    //             });
    //
    //             // New push
    //             const defaultData = {
    //                 'event': 'cta_click_out',
    //                 'elementClicked': 'housing cta zipsearch',
    //                 'destinationType': 'internal',
    //             };
    //             const dataLayerData = Object.assign(defaultData, buildDatalayerParams(elementTarget));
    //             window.dataLayer.push(dataLayerData);
    //
    //             console.log(dataLayerData);
    //         });
    //     });
    // }


    // Senior Finder Form button
    if (SENIOR_FINDER_ZIP_FORM) {
        SENIOR_FINDER_ZIP_FORM.forEach(form =>
            form.addEventListener('submit', (e) => {
                const elementTarget = e.currentTarget;
                // Old push
                window.dataLayer.push({
                    'event': 'housing_cta_zipsearch',
                    'pageContext': context,
                });

                console.log({
                    'event': 'housing_cta_zipsearch',
                    'pageContext': context,
                });

                const elementPlacement = elementTarget.getAttribute('data-elementplacement') || 'in-content';

                // New push for lead form entry
                window.dataLayer.push({
                    'event': 'cta_lead_form_click',
                    'elementPlacement': elementPlacement,
                    'elementClicked': 'Senior Housing Finder Zip form',
                    'pageType': pageTypeValue,
                    'href': elementTarget.action,
                });
            })
        );
    }

    if (PAGINATION) {
        PAGINATION.forEach(btn => {
            btn.addEventListener('click', (e) => {
                const elementTarget = e.currentTarget;
                const defaultData = {
                    'event': 'internal_link_click',
                    'elementClicked': elementClicked(elementTarget),
                    'destinationType': 'internal',
                    'elementPlacement': 'in-content',
                };
                window.dataLayer.push(defaultData);

                console.log(defaultData);
            });
        });
    }

    function triggerClickOutAction(elementTarget)
    {
        const defaultData = {
            'event': 'cta_click_out',
            'elementClicked': 'cta button',
            'destinationType': destinationType(elementTarget),
        };
        const dataLayerData = Object.assign(defaultData, buildDatalayerParams(elementTarget));
        window.dataLayer.push(dataLayerData);

        console.log(dataLayerData);

    }

    function triggerCallClickOutAction(elementTarget)
    {
        const defaultData = {
            'event': 'cta_call_click',
            'elementClicked': 'phone link',
            'destinationType': 'provider',
        };
        const dataLayerData = Object.assign(defaultData, buildDatalayerParams(elementTarget));
        window.dataLayer.push(dataLayerData);

        console.log(dataLayerData);

    }

    function triggerInternalExternalClick(elementTarget, linkType)
    {
        const eventAction = linkType === 'internal' ? 'internal_link_click' : 'external_link_click';
        const defaultData = {
            'event': eventAction,
            'elementPlacement': elementPlacement(elementTarget),
            'elementClicked': elementClicked(elementTarget),
            'pageType': pageTypeValue,
            'position': providerIndex(elementTarget),
            'href': ctaUrl(elementTarget),
            'linkText': linkText(elementTarget),
        };
        window.dataLayer.push(defaultData);
        console.log(defaultData);
    }

    function triggerOnPageClick(elementTarget)
    {
        const defaultData = {
            'event': 'on_page_link_click',
            'elementPlacement': elementPlacement(elementTarget),
            'elementClicked': elementClicked(elementTarget),
            'pageType': pageTypeValue,
            'pageUrl': window.location.href,
            'position': providerIndex(elementTarget),
            'linkText': linkText(elementTarget),
            'direction': getDirection(elementTarget),
        };
        window.dataLayer.push(defaultData);

        console.log(defaultData);
    }

    function triggerOnPageHover(elementTarget)
    {

        const defaultData = {
            'event': 'on_page_link_hover',
            'elementPlacement': elementPlacement(elementTarget),
            'elementHovered': elementClicked(elementTarget),
            'pageType': pageTypeValue,
            'pageUrl': window.location.href,
            'position': providerIndex(elementTarget),
            'linkText': linkText(elementTarget),
        };
        window.dataLayer.push(defaultData);

        console.log(defaultData);
    }

    // Referral Call click
    document.addEventListener('click', function (e) {
        const ctaClickout = e.target.closest(REFERRAL_CTA_SELECTOR);
        const callClickout = e.target.closest(REFERRAL_CALL_SELECTOR);
        const internalClickout = e.target.closest(INTERNAL_LINKS_SELECTOR);
        const externalClickout = e.target.closest(EXTERNAL_LINKS_SELECTOR);
        const onPageClickout = e.target.closest(ON_PAGE_LINKS_SELECTOR);

        if (ctaClickout) {
            triggerClickOutAction(ctaClickout);
        }

        if (callClickout) {
            triggerCallClickOutAction(callClickout);
        }

        if (internalClickout) {
            triggerInternalExternalClick(internalClickout, 'internal');
        }

        if (externalClickout) {
            triggerInternalExternalClick(externalClickout, 'external');
        }

        if (onPageClickout) {
            triggerOnPageClick(onPageClickout);
        }
    });

    // Implements hover and click tracking for on-page links
    if (ON_PAGE_LINKS_HOVER_CLICK_SELECTOR) {
        addMultipleEventListeners(ON_PAGE_LINKS_HOVER_CLICK_SELECTOR, ['click', 'mouseover'], [triggerOnPageClick, triggerOnPageHover]);
    }


    // Search Form
    if (SEARCH_FORM) {
        SEARCH_FORM.addEventListener('submit', () => {
            const defaultData = {
                'event': 'site_search',
                'searchTerm': document.querySelector('.search__field').value || '',
            };
            window.dataLayer.push(defaultData);

            console.log(defaultData);
        });
    }

}

export default function init() {
    triggerEventTracking();
}
