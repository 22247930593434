const
    TAKEOVER_BANNER_HEADER_BTN = document.querySelector('#takeover-notification-banner'),
    TAKEOVER_BANNER_FOOTER_BTN = document.querySelector('#takeover-notification-banner-footer'),
    TRENDING_BANNER_BTN = document.querySelector('#trending-banner'),
    PAGETYPE_FIELD = document.getElementById('pagetype');


function triggerSponsorTracking() {
    const pageTypeValue = PAGETYPE_FIELD ? PAGETYPE_FIELD.value : 'generic';

    // Takeover Banner header
    if (typeof TAKEOVER_BANNER_HEADER_BTN !== 'undefined' && TAKEOVER_BANNER_HEADER_BTN !== null) {
        const defaultData = {
            'event': 'sponsored_impression',
            'elementPlacement': 'top of page',
            'elementName': 'Takeover Banner',
            'serviceProvider': null,
            'pageType': pageTypeValue,
        };
        window.dataLayer.push(defaultData);

        console.log(defaultData);
    }

    // Takeover Banner header
    if (typeof TAKEOVER_BANNER_FOOTER_BTN !== 'undefined' && TAKEOVER_BANNER_FOOTER_BTN !== null) {
        const defaultData = {
            'event': 'sponsored_impression',
            'elementPlacement': 'bottom of page',
            'elementName': 'Takeover Banner',
            'serviceProvider': null,
            'pageType': pageTypeValue,
        };
        window.dataLayer.push(defaultData);

        console.log(defaultData);
    }

    // Takeover Banner header
    if (typeof TRENDING_BANNER_BTN !== 'undefined' && TRENDING_BANNER_BTN !== null) {
        const defaultData = {
            'event': 'sponsored_impression',
            'elementPlacement': 'top of page',
            'elementName': 'Trending Banner',
            'serviceProvider': null,
            'pageType': pageTypeValue,
        };
        window.dataLayer.push(defaultData);

        console.log(defaultData);
    }
}

export default function init() {
    triggerSponsorTracking();
}
