function triggerLinkTracking()
{

    const avoidElements = [
        '.finder-cta-btn',
        '#takeover-notification-banner a.btn',
        '#takeover-notification-banner-footer a.btn',
        '.banner-cta a',
        '#trending-banner a',
        '[data-ga="social-share"]',
        '[data-ga="related-article"]',
        '[data-ga="referral-cta"]',
        '[data-ga="referral-call"]',
        '.pagination .page-link',
    ];

    const linkedElements = {
        'header': {
            'logo': '.primary_navigation .brand',
            'nav': '.nav.main-menu',
            'subnav': '.primary_navigation .sub-menu',
        },
        'hero': {
            'author': '.author-link',
        },
        'footer': {
            'footer_logo': '.site-footer .brand',
            'footer_nav': '.site-footer .sub-menu',
            'social_footer': '.site-footer .social-container',
        },
        'content': {
            'citations': '.citation-wrapper .citation',
            'content-link': '.main-content',
            'toc': '.toc-menu-tracking a',
            'faq': '.faqs',
            'faq-content': '.faqs .accordion-content',
            'product-anchor': '.product-anchor-list',
        },
    };

    const isolatedElements = [
        'citations',
        'product-anchor',
    ];

    // const isParentNode = (node, parentNode) => {
    //     return node === parentNode ? false : parentNode.contains(node);
    // }

    const isExternalRegex = (function () {
        const domainRe = /https?:\/\/((?:[\w\d-]+\.)+[\w\d]{2,})/i;
        const goReg = /\/go\//;

        return (url) => {
            if (goReg.test(url)) {
                return true;
            }
            if (typeof url === 'undefined' || url === null) {
                return false;
            }
            if (url.includes('tel:') || url.includes('mailto:') || url === '#') {
                return false;
            }
            let response = false;
            if (typeof url !== 'undefined' && url !== null) {
                const domain = (url) => {
                    return (domainRe.exec(url) && domainRe.exec(url)[1]) || '';
                }
                response = domain(location.href) !== domain(url);
            }
            return response;
        };
    })();

    const setLinkTrackingAttr = (element, location, elementClicked, order, isolated = '', linkType = '') => {
        if (element.getAttribute('data-elementPlacement') === null) {
            element.setAttribute('data-elementPlacement', location);
        }
        if (element.getAttribute('data-elementClicked') === null) {
            element.setAttribute('data-elementClicked', elementClicked);
        }
        if (element.getAttribute('data-order') === null) {
            element.setAttribute('data-order', order);
        }
        if (element.getAttribute('data-link-type') === null) {
            if (linkType !== '' && isolated !== '') {
                element.setAttribute('data-link-type', linkType);
            } else {
                element.setAttribute('data-link-type', isExternalRegex(element.href) ? 'external' : 'internal');
            }
        }
    }

    const setupLinkTrackingAttr = (element, location, elementClicked) => {
        if (elementClicked === 'faq') {
            const links = element.querySelectorAll('a.accordion-title');
            let onPageLinkCount = 1;
            links.forEach(function (link) {
                link.setAttribute('data-elementPlacement', location);
                link.setAttribute('data-elementClicked', elementClicked);
                link.setAttribute('data-link-type', 'on-page');
                link.setAttribute('data-order', onPageLinkCount);
                onPageLinkCount++;
            });
        } else if (elementClicked === 'citations') {
            let linkCount = 1;
            element.forEach(function (clickableElement) {
                const checkStatus = clickableElement.getAttribute('data-skip-tracking');
                if (typeof checkStatus === 'undefined' || checkStatus === null) {
                    setLinkTrackingAttr(clickableElement, location, elementClicked, linkCount, 'isolated', 'on-page');
                    linkCount++;
                }
            });
        } else if (element.nodeName === 'A') {
            const checkStatus = element.getAttribute('data-skip-tracking');
            if (typeof checkStatus === 'undefined' || checkStatus === null) {
                setLinkTrackingAttr(element, location, elementClicked, 'single');
            }
        } else {
            const links = element.querySelectorAll('a');
            let linkCount = 1,
                onPageLinkCount = 1;
            links.forEach(function (link) {
                const checkStatus = link.getAttribute('data-skip-tracking');
                if (typeof checkStatus === 'undefined' || checkStatus === null) {
                    if (isolatedElements.indexOf(elementClicked) !== -1) {
                        setLinkTrackingAttr(link, location, elementClicked, onPageLinkCount, 'isolated', 'on-page');
                        onPageLinkCount++;
                    } else {
                        if (
                            link.classList.contains('brand') === false ||
                            link.parentNode.classList.contains('social_items') === false
                        ) {
                            setLinkTrackingAttr(link, location, elementClicked, linkCount);
                            if (location !== 'in-content') {
                                if (link.getAttribute('data-order') === null) {
                                    link.setAttribute('data-order', linkCount);
                                }
                            }
                            linkCount++;
                        }
                    }
                }
            });
        }
    }

    avoidElements.forEach(function (elements) {
        const queriedElements = document.querySelectorAll(elements);
        if (typeof queriedElements !== 'undefined' && queriedElements !== null) {
            queriedElements.forEach(function (element) {
                element.setAttribute('data-skip-tracking', 'true');
            });
        }
    });

    for (const location in linkedElements) {
        const linkElements = linkedElements[location];
        for (const elementClicked in linkElements) {
            let element = '';
            if (elementClicked === 'citations') {
                element = document.querySelectorAll(linkElements[elementClicked]);
            } else {
                element = document.querySelector(linkElements[elementClicked]);
            }
            if (typeof element !== 'undefined' && element !== null) {
                setupLinkTrackingAttr(element, location, elementClicked);
            }
        }
    }
}

export default function init()
{
    triggerLinkTracking();
}
