// Marked for UserInteraction
function addUrlParams() {

    function addParams(btn, type) {
        let url = btn.getAttribute('href'),
            ringpool = btn.getAttribute('data-ringpoolid'),
            dnis = btn.getAttribute('data-dnis'),
            phone = btn.getAttribute('data-phone'),
            urlObj = new URL(url, window.site_settings.base_url);

        // Get existing URL search parameters
        let existingParams = new URLSearchParams(urlObj.search);

        if (type === 'enter') {
            // Only add parameters if they are not already in the URL
            if (ringpool && !existingParams.has('ringpool')) {
                existingParams.set('ringpool', ringpool);
            }
            if (dnis && !existingParams.has('dnis')) {
                existingParams.set('dnis', dnis);
            }
            if (phone && !existingParams.has('phone')) {
                existingParams.set('phone', phone);
            }

            // Set the updated query string
            urlObj.search = existingParams.toString();
            btn.setAttribute('href', urlObj.toString());
        } else {
            // Remove query string if present
            urlObj.search = ''; // Clear query string
            btn.setAttribute('href', urlObj.toString());
        }
    }

    const btnElement = '[data-ga="referral-cta"]';

    function handlePointerEnter(event) {
        const target = event.target;
        if (target.matches(btnElement + ' *')) {
            // handle pointer enter on child elements
            addParams(target.parentElement, 'enter');
        } else if (target.matches(btnElement)) {
            // handle pointer enter on parent element
            addParams(target, 'enter');
        }
    }

    function handlePointerLeave(event) {
        const target = event.target;
        if (target.matches(btnElement + ' *')) {
            // handle pointer leave on child elements
            addParams(target.parentElement, 'leave');
        } else if (target.matches(btnElement)) {
            // handle pointer leave on parent element
            addParams(target, 'leave');
        }
    }

    document.addEventListener('mouseover', handlePointerEnter);
    document.addEventListener('touchstart', handlePointerEnter);
    document.addEventListener('mouseout', handlePointerLeave);
    document.addEventListener('touchend', handlePointerLeave);

}

export default function init() {
    addUrlParams();
}

