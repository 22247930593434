/* eslint-disable */
// Video Schema for Youtube videos

function youtubeSchema() {
    const youtubeElm = document.querySelectorAll('.rll-youtube-player');
    const regExIsYouTube = new RegExp(/(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/ ]{11})/i);
    let id;

    if (youtubeElm.length) {
        youtubeElm.forEach((e) => {
            var url = e.firstElementChild.getAttribute('data-src');
            if (typeof url !== 'undefined' && url !== null) {
                if (url.match(regExIsYouTube)) {
                    id = url.match(regExIsYouTube)[1];
                    if (id) {
                        var url = window.site_settings.admin_ajax + '?action=get_youtube_schema&videoId=' + id;
                        var xhttp2 = new XMLHttpRequest();
                        xhttp2.onreadystatechange = function () {
                            if (this.readyState == 4 && this.status == 200) {
                                var responseObject = JSON.parse(this.response);
                                var video = responseObject.items[0];
                                var jsonld = {
                                    "@context": "http://schema.org",
                                    "@type": "VideoObject",
                                    "name": video.snippet.title,
                                    "description": video.snippet.description,
                                    "thumbnailUrl": [
                                        video.snippet.thumbnails.default.url,
                                        video.snippet.thumbnails.medium.url,
                                        video.snippet.thumbnails.high.url
                                        // , video.snippet.thumbnails.maxres1.url
                                    ],
                                    "uploadDate": video.snippet.publishedAt,
                                    "contentUrl": "https://www.youtube.com/watch?v=" + video.id,
                                    "embedUrl": "https://www.youtube.com/embed/" + video.id
                                };
                                var script = document.createElement("script");
                                script.type = "application/ld+json";
                                script.text = JSON.stringify(jsonld);
                                document.querySelector('head').append(script);
                            }
                        };
                        xhttp2.open("GET", url, true);
                        xhttp2.send();
                    }
                }
            }
        });
    }
}

export default function init() {
    document.addEventListener('DOMContentLoaded', function () {
        youtubeSchema();
    });
}
