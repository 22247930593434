
import tippy from 'tippy.js';

function tippyTooltip() {

    const TOOLTIPS_SELECTOR = '.has-tooltip',
        tooltips = Array.prototype.slice.call(document.querySelectorAll(TOOLTIPS_SELECTOR)),
        perBatch = 25;

    function tooltipInitializationStep(currentIndex, tooltipsLen)
    {
        const possibleBatchEnd = currentIndex + perBatch;
        const batchEnd = possibleBatchEnd >= tooltipsLen ? tooltipsLen : possibleBatchEnd;

        for (currentIndex; currentIndex < batchEnd; currentIndex++) {
            let tooltip = tooltips[currentIndex];
            tippy(tooltip, getTooltipOptions(tooltip));
        }

        if (currentIndex < tooltipsLen) {
            window.requestAnimationFrame(tooltipInitializationStep);
        }
    }

    function getTooltipOptions(tooltip)
    {
        let fallbackPlacements = [];

        if (
            tooltip
            && typeof tooltip.dataset.fallbackPlacement !== 'undefined'
            && tooltip.dataset.fallbackPlacement !== ''
        ) {
            fallbackPlacements.push(tooltip.dataset.fallbackPlacement);
        }

        return {
            content: tooltip.querySelector('.tooltip-content').innerHTML,
            allowHTML: true,
            trigger: 'mouseenter click',
            maxWidth: 558,
            popperOptions: {
                modifiers: [
                    {
                        name: 'flip',
                        options: {
                            fallbackPlacements,
                        },
                    },
                ],
            },
        };
    }

    if (tooltips && tooltips.length > 0) {
        let currentIndex = 0;
        const tooltipsLen = tooltips.length;

        tooltipInitializationStep(currentIndex, tooltipsLen);
    }
}

export default function init() {
    tippyTooltip();
}
