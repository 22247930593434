import {getCookie, setCookie} from '../ringpoolAuto/cookies';

const SEM_USER_COOKIE_NAME = 'PPCUser';
const SEM_USER_COOKIE_VALUE = '1';
const SEM_USER_QUERY_NAME = 'cfsource';
const SEM_USER_QUERY_VALUE = 'sem';
const MT_USER_QUERY_NAME = 'customnative6';
const MT_USER_S7_COOKIE_NAME = 'MTUSER_S7';
const SWIMLANE_COOKIE_NAME = 'cf_trk_swimlane';
const NUMBER_OF_SWIMLANES = 4;
const SWIMLANE_EXPIRATION_DAYS = 30;

/**
 * Determine whether the current user is an SEM user or not.
 * @returns {boolean} - if the user is an SEM user or not
 */
export function isSemUser() {
    if(
        !isCrawler()
        &&
        (
            getCookie(SEM_USER_COOKIE_NAME) === SEM_USER_COOKIE_VALUE
            || getQSValue(SEM_USER_QUERY_NAME) === SEM_USER_QUERY_VALUE
        )
    ) {
        return true;
    } else {
        return false;
    }
}

/**
 * Determine whether the current user is a Media Transformation user or not.
 * @returns {boolean} - if the user is a MT user or not
 */
export function isMTUser() {
    let $queryParamValue = getQSValue(MT_USER_QUERY_NAME);
    let $cookieValue = getCookie(MT_USER_S7_COOKIE_NAME);

    if(!isCrawler() && ($queryParamValue || $cookieValue)
    ) {
        // Update cookie if query param is set
        if($queryParamValue && $queryParamValue !== $cookieValue) {
            setCookie(MT_USER_S7_COOKIE_NAME, $queryParamValue);
        }

        return true;
    } else {
        return false;
    }
}

/**
 * Fetch the swimlane cookie and return the swimlane id as an integer.
 * @returns {null|number} - the swimlane id, or null if there is no cookie, or the cookie is set to an invalid value
 */
export function getSwimlane() {
    const swimlaneCookie = getCookie(SWIMLANE_COOKIE_NAME);
    if(swimlaneCookie.length > 0) {
        const swimlane = parseInt(swimlaneCookie, 10);
        if(!isNaN(swimlane) && swimlane > 0 && swimlane <= NUMBER_OF_SWIMLANES) {
            return swimlane;
        } else {
            return null;
        }
    }

    return null;
}

/**
 * Set the swimlane for the current user.
 * If the user already has a swimlane, this function will do nothing.
 * So it is safe to call in any circumstance.
 */
export function assignSwimlaneToUser() {
    if(getSwimlane() === null) {
        // assign a new swimlane!
        const swimlane = 1 + Math.floor(Math.random() * NUMBER_OF_SWIMLANES);

        // swimlane lasts 24 hours
        const expiration = new Date();
        expiration.setTime(expiration.getTime() + (SWIMLANE_EXPIRATION_DAYS * 24 * 60 * 60 * 1000));

        document.cookie = `${SWIMLANE_COOKIE_NAME}=${swimlane};path=/;expires=${expiration.toUTCString()}`;
    }
}

// TEST VARIANT E
export function childPixelScript(childPixelData, trackingRequestComplete) {
    console.log('tracking -- child pixel script loaded');
    const ccid_sem = childPixelData.residential?.sem ? childPixelData.residential.sem : '';
    const ccid_seo = childPixelData.residential?.seo ? childPixelData.residential.seo : '';
    const urlTrackBase = '//analytics.broadbandnow.com/Resources/JS/cpseov2_101615';

    function childClick(){
        if (typeof window.getCookie === 'function') {
            console.log('running child pixel script');
            var scriptTag = document.createElement('script');
            if (getCookie('PPCUser')) {
                scriptTag.src = urlTrackBase + '_' + ccid_sem + '.js';
            } else {
                scriptTag.src = urlTrackBase + '_' + ccid_seo + '.js';
            }
            document.body.appendChild(scriptTag);
        } else {
            setTimeout(childClick, 100);
        }
    }
    if(trackingRequestComplete){
        childClick();
    }
}

/**
 * Read query parameter from current URL
 * @param key - which parameter to read
 * @returns {string} - parameter value
 */
export function getQSValue(key) {
    return decodeURIComponent(
        window.location.search.replace(
            new RegExp(
                '^(?:.*[&\\?]' + encodeURIComponent(key).replace(/[.+*]/g, '\\$&') + '(?:\\=([^&]*))?)?.*$',
                'i'
            ), '$1'
        ));
}

/**
 * Determine whether the current user agent is a crawler.
 * @returns {boolean} - true the user agent is a crawler, false if not
 */
function isCrawler() {
    const userAgent = navigator.userAgent? navigator.userAgent.toLocaleLowerCase() : '';
    const patternt = '/Googlebot|Mediapartners-Google|AdsBot-Google|googleweblight|Storebot-Google|Google-PageRenderer|Bingbot|BingPreview|Slurp|DuckDuckBot|baiduspider|yandex|sogou|LinkedInBot|bitlybot|tumblr|vkShare|quora link preview|facebookexternalhit|facebookcatalog|Twitterbot|applebot|redditbot|Slackbot|Discordbot|WhatsApp|SkypeUriPreview|ia_archiver/'.toLocaleLowerCase();
    const regex = new RegExp(patternt, 'i');

    if(userAgent !== '') {
        if (regex.test(userAgent)) {
            return true;
        } else {
            return false;
        }
    }

    return false;
}
