const minSizes = {
    laptop: 992,
    tab: 768,
    mobile: 480,
};

const getScreenSize = () => {
    const width = window.innerWidth;
    let activeScreenType = 'default';

    if (width > minSizes.laptop) {
        activeScreenType = 'desktop';
    } else if (width > minSizes.tab) {
        activeScreenType = 'tab';
    } else if (width > minSizes.mobile) {
        activeScreenType = 'mobile';
    }

    return activeScreenType;
};

window.activeScreenType = getScreenSize();

window.addEventListener('resize', () => {
    const tempScrnSize = getScreenSize();
    if (tempScrnSize === window.activeScreenType) {
        return;
    }
    window.activeScreenType = tempScrnSize;
});
