import './utilities/screen-sizes';
import './components/menu';
import runOnUserInteraction from './utilities/run-on-interaction';
import addUrlParams from './components/add-url-params';
import youtubeSchema from './components/youtube-schema';
import triggerEventTracking from './components/analytics/cta-events';
import triggerProviderTracking from './components/analytics/providers';
import triggerLinkTracking from './components/analytics/links';
import triggerSponsorTracking from './components/analytics/banners';
import {ringpoolSEM} from './modules/sem-ringpool';
import medicareBanner from './components/medicare-banner';
import tippyTooltip from './components/tippy-tooltip';


const userInteractionScripts = () => {
    addUrlParams();
    youtubeSchema();
    triggerEventTracking();
    triggerProviderTracking();
    triggerLinkTracking();
    triggerSponsorTracking();
    ringpoolSEM();
    medicareBanner();
    tippyTooltip();
}

runOnUserInteraction(userInteractionScripts);
