import { isLocalStorageAvailable } from './localStorage';
import PolyPromise from 'promise-polyfill';
import { getQSValue, isSemUser } from '../modules/tracking';

const LOCAL_STORAGE_KEY = 'sl-go-experiment-data';
const GA4_MEASUREMENT_ID = 'G-G17C4Z1KHF';
const POLL_INTERVAL = getQSValue('poll_interval') || 15;
const POLL_MAX_COUNT = getQSValue('poll_max_count') || 60;

const debugGoogleFetcherEvent = (data) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        ...data,
    });

    console.log('GoogleDataFetcher', data);
};


if (!window.slGoogleDataFetcher) {
    // Initialize Google Fetcher
    debugGoogleFetcherEvent({
        'event': 'InitializingGoogleFetcher',
    });

    // When webpack builds multiple entry points then we cannot enforce the modules
    // to be evaluated only once per page as we compose multiple entry points in the template
    // That is why I am using a window variable here to share that instance between different webpack entry points.
    window.slGoogleDataFetcher = {
        analyticsPromise: new PolyPromise((resolve) => {
            let gaCounter = 0;

            function pollForAnalytics() {
                // console.debug('pollForAnalytics');

                if (gaCounter === POLL_MAX_COUNT) {
                    try {
                        const gaFromCookie = document.cookie.match(/_ga=(.+?);/)[1]?.split('.')?.slice(-2)?.join('.');
                        if (gaFromCookie != null) {
                            debugGoogleFetcherEvent({
                                'event': 'AnalyticsClientIdLoadedFromCookies',
                            });
                            return resolve({'clientId': gaFromCookie });
                        }
                    } catch {
                        debugGoogleFetcherEvent({
                            'event': 'GATimedOutWithNoId',
                        });
                        return resolve(null);
                    }
                }

                gaCounter++;
                if (window.gtag) {
                    // Use the GA4 API to get the client_id directly
                    window.gtag('get', GA4_MEASUREMENT_ID, 'client_id', function(client_id) {
                        if (client_id) {
                            debugGoogleFetcherEvent({
                                'event': 'AnalyticsClientIdLoaded',
                            });

                            return resolve({'clientId': client_id });
                        } else {
                            debugGoogleFetcherEvent({
                                'event': 'GAClientIdNotFound',
                            });

                            return resolve({'clientId': null});
                        }
                    });
                } else {
                    setTimeout(pollForAnalytics, POLL_INTERVAL);
                }
            }

            pollForAnalytics();
        }),

        getExperimentData: () => {
            // Resolve if SEM, we don't have Experiments
            if(isSemUser()) {
                return null;
            }

            let experiment = window.site_settings?.experiment ?? (window?.experiment_settings?.experiment ?? {});

            return {
                'experimentId': experiment?.id ?? null,
                'variationId': experiment?.variant ?? null,
            };
        },

        // Fetch GO & GA data
        async fetchData() {
            debugGoogleFetcherEvent({
                'event': 'GAGOFetchStarted',
            });

            return PolyPromise.all([this.analyticsPromise])
                .then(values => {
                    let analyticsValues = (values[0] !== null) ? values[0] : null;

                    let googleData = {
                        'analyticsData': analyticsValues,
                    };
                    debugGoogleFetcherEvent({
                        'event': 'GAGODataSettled', values, googleData,
                    });
                    return googleData;
                });
        },

        // Load values from the cache
        getCachedData() {
            if (isLocalStorageAvailable()) {
                const cachedJson = localStorage.getItem(LOCAL_STORAGE_KEY) || null;
                if (cachedJson) {
                    try {
                        let cachedData = JSON.parse(cachedJson);
                        let expirationDate = parseInt(cachedData.expires, 10);
                        const now = +new Date();
                        if (!isNaN(expirationDate)) {
                            expirationDate = new Date(expirationDate);
                        }
                        if (isNaN(expirationDate) || now >= expirationDate) {
                            localStorage.removeItem(LOCAL_STORAGE_KEY);
                            return null;
                        }
                        return cachedData;
                    } catch (e) {
                        return null;
                    }
                }
            }
            return null;
        },

        // Save the values retrieved to the cache
        setCacheData(jsonData) {
            if(jsonData.optimizeData !== null) {
                if (isLocalStorageAvailable()) {
                    const now = +new Date();
                    const expiration = now + (1000 * 60 * 60 * 6); // six hours
                    const cacheObj = {
                        analyticsData: jsonData.analyticsData,
                        expires: expiration,
                    };
                    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(cacheObj));

                    debugGoogleFetcherEvent({
                        'event': 'SetCacheData', cacheObj,
                    });
                }
            }
        },

        // Retrieve google data by Fetching or From Cache
        async getGoogleData() {
            let googleData = null;
            let cachedData = this.getCachedData();

            if (cachedData === null) {
                await this.fetchData()
                    .then(values => {
                        // Save results to local storage
                        this.setCacheData(values);

                        googleData = {
                            analyticsData: values.analyticsData,
                        };
                    });
            } else {
                googleData = {
                    analyticsData: cachedData.analyticsData,
                };

                debugGoogleFetcherEvent({
                    'event': 'LoadingGAGOFromCache', cachedData,
                });
            }
            const experimentData = this.getExperimentData();
            window.slGoogleClientId = googleData.analyticsData?.clientId;
            // Check null for SEM, We don't have SEM Experiments
            window.slGoogleExperimentId = experimentData && experimentData.experimentId ? experimentData.experimentId : null;
            window.slGoogleVariationId = experimentData && experimentData.variationId ? experimentData.variationId : null;

            googleData.optimizeData = experimentData;

            return new PolyPromise((resolve) => {
                return resolve(googleData);
            });
        },
    };
}

export default window.slGoogleDataFetcher;
