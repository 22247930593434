const
    PROVIDER_SELECTOR = document.querySelectorAll('.product-listings-container .product-listing, .properties-list .property-card'),
    PAGETYPE_FIELD = document.getElementById('pagetype');

function triggerProviderTracking() {

    const pageTypeValue = PAGETYPE_FIELD ? PAGETYPE_FIELD.value : 'generic';

    // Get Page Type of CTA
    function pageType($element) {

        return $element.dataset.pagetype || pageTypeValue;
    }

    // Get Index of CTA
    function providerIndex($element) {

        return $element.dataset.order || 'single'
    }

    // Get Product Name of CTA
    function productName($element) {

        return $element.dataset.productname || null;
    }

    const listingData = [];

    PROVIDER_SELECTOR.forEach(provider => {
        listingData.push({
            'event': 'provider_listing_position',
            'position': providerIndex(provider),
            'pageType': pageType(provider),
            'serviceProvider': productName(provider),
        });

        console.log({
            'event': 'provider_listing_position',
            'position': providerIndex(provider),
            'pageType': pageType(provider),
            'serviceProvider': productName(provider),
        });
    });

    window.dataLayer.push(...listingData);
}

export default function init() {
    if(PROVIDER_SELECTOR.length > 0) {
        triggerProviderTracking();
    }
}
